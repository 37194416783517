import { format } from "date-fns";
import { Override } from "../types";
import {
  AssistType,
  DailyHabit as HabitDto,
  Event as EventDto,
  PlannerActionIntermediateResult,
  Task as TaskDto,
  TaskOrHabit as TaskOrHabitDto,
} from "./client";
import { dtoToEvent, Event } from "./Events";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToTask, Task } from "./Tasks";
import { NotificationKeyStatus, TransformDomain } from "./types";

export type TaskOrHabit = Override<
  TaskOrHabitDto,
  {
    title: string | null;
    location?: string | null;
  }
>;

export const isTask = (taskOrHabit: TaskOrHabit): taskOrHabit is TaskDto => taskOrHabit.type === AssistType.TASK;
export const isHabit = (taskOrHabit: TaskOrHabit): taskOrHabit is HabitDto =>
  !!taskOrHabit.type && taskOrHabit.type !== AssistType.TASK;

export type PlannerActionResult = {
  events: Event[];
  task?: Task;
  habit?: Habit;
};

export type PlannerActionQuery = {
  minutes?: number,
  eventId?: string,
  end?: Date,
  date?: Date,

};

export const fromDto = (dto: PlannerActionIntermediateResult): PlannerActionResult => {
  const result: PlannerActionResult = {
    events: dto.events.map((event: EventDto) => dtoToEvent(event)),
  };

  if (isTask(dto.taskOrHabit as TaskOrHabit)) {
    result.task = dtoToTask(dto.taskOrHabit as TaskDto);
  } else {
    result.habit = dtoToHabit(dto.taskOrHabit as HabitDto);
  }

  return result;
};

export class PlannerDomain extends TransformDomain<PlannerActionResult, PlannerActionIntermediateResult> {
  resource = "Planner";
  cacheKey = "planner";

  public deserialize = fromDto;

  // handleResult = (result: PlannerActionResult | null): PlannerActionResult | null => {
  private handleResult =
    (notificationKey?: string) =>
    (res: PlannerActionIntermediateResult): PlannerActionIntermediateResult => {
      if (!!notificationKey) this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);

      const result = this.deserialize(res);
      if (!!result?.task) this.client.tasks.upsert(result.task);
      if (!!result?.habit) this.client.habits.upsert(result.habit);
      if (!!result?.events) this.client.events.upsert(result.events);

      return res;
    };

  handleError = (reason, message: string, notificationKey: string) => {
    console.warn("Request failed", message);
    this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);

    throw reason;
  };

  setupNotification = (id: number): string => {
    const notificationKey = this.generateUid("planner", id);
    this.expectChange(notificationKey, id, {}, true);

    return notificationKey;
  };

  /**
   * Task Actions
   */

  // Extends the time on an in-flight/active instance
  extendTask = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .extendTask(id, { minutes: query?.minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend task", notificationKey));
    })
  );

  // Adds time to the task policy
  addTime = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .addTime(id, { minutes: query?.minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend task", notificationKey));
    })
  );

  logWork = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      const end = !!query?.end ? query.end.toISOString() : undefined;

      return this.api.planner
        .logWork(id, { minutes: query?.minutes, end, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not log work for task", notificationKey));
    })
  );

  restartTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .restartTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not restart task", notificationKey));
    })
  );

  snoozeTask = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .snoozeTask(id, { minutes: query?.minutes, eventId: query?.eventId, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not snooze task", notificationKey));
    })
  );

  pushTask = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const date = !!query?.date ? format(query.date, "yyyy-MM-dd") : undefined;
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .pushTask(id, { date, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not pull task", notificationKey));
    })
  );

  pullTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .pullTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not pull task", notificationKey));
    })
  );

  startTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not start task now", notificationKey));
    })
  );

  stopTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .stopTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not stop task", notificationKey));
    })
  );

  doneTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .doneTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark task as done", notificationKey));
    })
  );

  unarchiveTask = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .unarchiveTask(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not unarchive task", notificationKey));
    })
  );

  /**
   * Habit Actions
   */

  extendHabit = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .extendHabit(id, { minutes: query?.minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not extend habit", notificationKey));
    })
  );

  restartHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .restartHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not restart habit", notificationKey));
    })
  );

  // TODO (SS): Remove default once not required on backend
  snoozeHabit = this.manageErrors(
    this.deserializeResponse((id: number, query?: PlannerActionQuery) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .snoozeHabit(id, { minutes: query?.minutes, notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not snooze habit", notificationKey));
    })
  );

  startHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .startHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not start habit now", notificationKey));
    })
  );

  stopHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .stopHabit(id)
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not stop habit", notificationKey));
    })
  );

  doneHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .doneHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) => this.handleError(reason, "Request failed: Could not mark habit as done", notificationKey));
    })
  );

  incompleteHabit = this.manageErrors(
    this.deserializeResponse((id: number) => {
      const notificationKey = this.setupNotification(id);

      return this.api.planner
        .incompleteHabit(id, { notificationKey })
        .then(this.handleResult(notificationKey))
        .catch((reason) =>
          this.handleError(reason, "Request failed: Could not mark habit as incomplete", notificationKey)
        );
    })
  );
}
